<template>
  <router-link
    v-if="event"
    :to="{ name: 'EventDetails', params: { id: event.id } }"
    class="eventLink"
  >
    <div class="eventCard">
      <span class="date">@ {{ event.time }} on {{ event.date }}</span>
      <h4 class="title">
        {{ event.title }}
      </h4>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: Object,
  },
};
</script>

<style scoped lang="scss">
.eventLink {
  display: block;
  color: inherit;
  text-decoration: none;

  + .eventLink {
    margin-top: 1em;
  }
}
.eventCard {
  border: 2px solid #f3f3f3;
  background-color: white;
  max-width: 24em;
  margin: auto;
  padding: 2em;
  border-radius: 0.25em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.2s ease-out;
  will-change: transform;

  &:hover {
    transform: translate3d(0, 0, 10px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.1) 0px 6px 12px -1px;
  }
  cursor: pointer;
  .title {
    font-size: 1.2em;
    margin: 0;
  }
}
.date {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  color: gray;
}
.placeholder {
  min-height: 6em;
  background: red;
}
</style>
